import React, { Component } from "react";
import { connect } from "react-redux";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/image/new_logo.svg";
import avatar from "../../assets/image/avatar_placeholder.png";
import search from "../../assets/image/search.svg";
import user_grey from "../../assets/image/user_grey.svg";
import product_grey from "../../assets/image/product_grey.svg";
import user_red from "../../assets/image/user_red.svg";
import product_red from "../../assets/image/product_red.svg";
import { logout, personalInfo, getHeaderDropData, getHeaderDropShop, setVisiblePrice } from "../../actions/authActions";
import { getSearchResults } from "../../actions/searchActions";
import nonAvatart from "../../assets/image/non-avatar.png";
import SendClient from "../../assets/image/Union.png";
import Frame_big from "../../assets/image/Frame_big.svg";
import barcodeIcon from "../../assets/image/barcode-scan.svg";
import { ReactComponent as BarcodeIcon } from "../../assets/image/barcode-scan.svg";
import DefaultButton from "../HelperComponents/DefaultButton/DefaultButton";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import burger_img from "../../assets/image/header-menu.svg";
import cart_img from "../../assets/image/header-cart.svg";
import moment from "moment";
import quitImg from "../../assets/image/quit.svg";
import statsImg from "../../assets/image/stats.svg";
import motivationImg from "../../assets/image/motivation.svg";
import Sidebar from "../CategorySidebar/CategorySidebar";
import { toFixedNoRounding } from "../../helpers/functions";
import CustomSpinner from "../HelperComponents/CustomSpinner/CustomSpinner";
import CartDialog from "./CartDialog";
import axios from "axios";

import {
    cartUpdate,
    GetClientInfo,
    sendToPicking,
    sessionEnd,
    setAddress, setDate,
    setNeedDelivery
} from "../../actions/clientAuthActions";

import "./Header.scss";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuIsOpen: false,
            loading: true,
            searchValue: props.history.location.pathname.split("/search/")[1] || "",
            consultationStatus: localStorage.getItem("consultationStatus") || "finish",
            clientInfo: JSON.parse(localStorage.getItem("client_info")),
            cart: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
            liveSearch: false,
            clientUrl: "",
            clientModal: "close",
            buttonLoad: false,
            clientModalSuccess: false,
            sendClientError: "false",
            catalogOpen: false,
            loadingDrop: true,
            loadingShop: true,
            openCart: false,
        };
        this.cancelToken = undefined;
    }

    toggleDialog = () => {
        this.setState(({ openCart }) => ({
            openCart: !openCart
        }));
    };


    componentDidUpdate(prevProps, prevState) {
        if (this.props.loc === "/main/client") {
            this.redirect();
        }
        if (!this.props.loc.includes("/client")) {
            if (
                prevProps.loc.includes("/client/found") ||
                prevProps.loc.includes("/client/not-found") ||
                prevProps.loc.includes("/client/registration")
            ) {
                this.setState({ clientUrl: prevProps.loc });
            }
        }
        if (
            (this.props.productsError && !prevProps.productsError) ||
            (this.props.categoryError && !prevProps.categoryError)
        ) {
            localStorage.removeItem("token");
            localStorage.setItem("is_logged", "false");
            this.props.history.push("/auth/login");
        }
        if (prevState.searchValue !== this.state.searchValue) {
            this.searchFunc(this.state.searchValue)
        }
    }

    componentDidMount() {
        this.props.personalInfo().then(res => {
            this.setState({ loading: false });
            if (res.error) {
                localStorage.removeItem("token");
                this.props.history.push("/auth/login");
            }
        });
        if (this.props.loc === "/main/client") {
            this.redirect();
        }
    }

    redirect = () => {
        const { history } = this.props;
        const { clientUrl } = this.state;
        if (localStorage.getItem("regForm")) {
            history.push("/main/client/registration");
        } else if (!localStorage.getItem("client_phone") || localStorage.getItem("client_info") === null) {
            if (clientUrl === "") {
                history.push("/main/client/login");
            } else {
                history.push(`${clientUrl}`);
            }
        } else {
            history.push("/main/client/info");
        }
    };

    handleOpenMenu = () => {
        setTimeout(() => {
            this.setState({
                menuIsOpen: false
            });
        }, 100);
    };

    openMenu = () => {
        const { getHeaderDropData, getHeaderDropShop } = this.props;
        this.setState(({ menuIsOpen }) => ({
            menuIsOpen: !menuIsOpen,
            loadingDrop: true
        }));
        // getHeaderDropData().then(res => {
        //     if (res.payload && res.payload.status && res.payload.status === 200) {
        //         this.setState({ loadingDrop: false });
        //     } else if (res.error) {
        //     }
        // });
        getHeaderDropShop(localStorage.getItem("storage_id")).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loadingDrop: false });
            } else if (res.error) {
            }
        });
    };

    handleOut = () => {
        const { history, logout } = this.props;
        logout().then(res => {
            //if (res.payload && res.payload.status && res.payload.status === 200) {
            localStorage.clear();
            history.push("/auth/login");
            //};
        });
    };

    searchFunc = (value) => {
        if (value.replace(/\s/g, "").length > 0) {
            const { getSearchResults } = this.props;
            try {
                if (this.cancelToken) {
                    this.cancelToken.cancel('cancel');
                }
                this.cancelToken = axios.CancelToken.source();
                getSearchResults(value, this.cancelToken).then(() => {
                    this.setState({ searchError: "", liveSearch: this.state.searchValue.replace(/\s/g, "").length > 0 });
                });
            } catch (error) {
                console.log(error)
            }
        } else {
            this.setState({ searchError: "", liveSearch: false });
        }
    }

    changeSearchInput = ({ target: { value } }) => {
        this.setState({ searchValue: value });
        //this.searchFunc(value)
    };

    inputEnter = e => {
        if (e.keyCode === 13) {
            this.applySearch();
        }
    };

    searchFocus = () => {
        const { searchValue } = this.state;
        this.searchFunc(searchValue)
    };

    applySearch = () => {
        const { searchValue } = this.state;
        const { history } = this.props;

        if (searchValue.replace(/\s/g, "").length > 0) {
            this.setState({ liveSearch: false });
            history.push(`/main/shop/search/${searchValue}`);
        } else {
            this.setState({
                liveSearch: false,
                searchError: "Поисковой запрос не может быть пустым или состоять только из пробелов"
            });
            setTimeout(() => this.setState({ searchError: "" }), 3000);
        }
    };

    goToProd = slug => {
        const { history } = this.props;

        history.push(`/main/shop/product/${slug}`);
    };

    goToCat = relative_url => {
        const { history } = this.props;

        history.push(`/main/shop?cat=${relative_url}`);
    };

    toggleClientModal = status => {
        this.setState(({ clientModal, buttonLoad }) => ({
            clientModal: !!status ? status : !clientModal,
            buttonLoad: status === "close" ? false : buttonLoad,
            sendClientError: "false"
        }));
    };

    sendClient = () => {
        const { sendToPicking } = this.props;
        this.setState({ buttonLoad: true });
        sendToPicking(localStorage.getItem("storage_id")).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({
                    buttonLoad: false,
                    clientModal: false,
                    clientModalSuccess: true
                });
            } else {
                this.setState({
                    buttonLoad: false,
                    sendClientError: res.error.response.data.detail
                });
            }
        });
    };

    render() {
        const {
            menuIsOpen,
            loading,
            searchError,
            liveSearch,
            searchValue,
            clientModal,
            buttonLoad,
            clientModalSuccess,
            sendClientError,
            catalogOpen,
            loadingDrop,
            clientInfo,
            openCart
        } = this.state;

        const { accountInfo, location, handleSubmit, searchResult /* , cart */, data, searchLoading, visiblePrice, setVisiblePrice } = this.props;
        if (loading) return null;
        let cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
            total = cart && toFixedNoRounding(
                cart.reduce(
                    (x, y) => x + (y.item.discount_price ? y.item.discount_price : y.item.retail_price) * y.amount,
                    0
                ),
                2
            ),
            discount = cart && toFixedNoRounding(
                (cart
                    .filter(
                        searchedElement =>
                            searchedElement.stock !== 0 &&
                            !searchedElement.item.discount_price !== null &&
                            !searchedElement.discount !== null
                    )
                    .reduce((x, y) => (x + (y.item.retail_price * (y.amount > y.stock ? y.stock : y.amount))), 0) *
                    (!!clientInfo ? clientInfo.user_discount : 0)) /
                100,
                2
            ),
            totalInStock = cart && toFixedNoRounding(
                cart
                    .filter(searchedElement => searchedElement.stock !== 0)
                    .reduce(
                        (x, y) =>
                            x +
                            (y.item.discount_price ? y.item.discount_price : y.item.retail_price) *
                            (y.amount > y.stock ? y.stock : y.amount),
                        0
                    ),
                2
            ),
            specialDiscount = cart && toFixedNoRounding(
                cart
                    .filter(searchedElement => searchedElement.stock !== 0 && searchedElement.discount !== null)
                    .reduce(
                        (x, y) =>
                            x + ((y.item.retail_price * y.discount) / 100) * (y.amount > y.stock ? y.stock : y.amount),
                        0
                    ),
                2
            ),
            totalInStockDiscount = toFixedNoRounding(Number(discount) + Number(specialDiscount), 2),
            totalInStockWithDiscount = toFixedNoRounding(totalInStock - totalInStockDiscount, 2),
            totalOutStock = cart && toFixedNoRounding(
                cart
                    .filter(searchedElement => searchedElement.amount > searchedElement.stock)
                    .reduce(
                        (x, y) =>
                            x +
                            (y.item.discount_price ? y.item.discount_price : y.item.retail_price) *
                            (y.stock !== 0 ? y.amount - y.stock : y.amount),
                        0
                    ),
                2
            ),
            totalAll = toFixedNoRounding(Number(totalOutStock) + Number(totalInStockWithDiscount), 2),

            totalWithDiscount = toFixedNoRounding(Number(totalInStockWithDiscount) + Number(totalOutStock), 2);
        return (
            <header className="header_wrapper">
                <div className={`sidebar-wrapper${catalogOpen ? " open" : ""}`}>
                    <Sidebar
                        closeEvent={() => {
                            this.setState({ catalogOpen: false });
                        }}
                        catalogOpen={catalogOpen}
                    />
                </div>
                <div className="head_block">
                    <div
                        className="burger-img"
                        onClick={() => {
                            setTimeout(() => this.setState({ catalogOpen: true }), 100);
                        }}
                    >
                        <img src={burger_img} alt="burger" />
                    </div>
                    <div className="logo">
                        <Link className="good_hover" to={`/main/shop`}>
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <div className="search_wrapper">
                        <span>
                            <img src={search} alt="search" />
                        </span>
                        <input
                            onChange={this.changeSearchInput}
                            onKeyDown={this.inputEnter}
                            onFocus={this.searchFocus}
                            value={searchValue}
                            type="text"
                            placeholder="Поиск "
                        />
                        {/* <button onClick={this.applySearch} className="good_hover">
                            Поиск{" "}
                        </button> */}
                        <span className={`error_text`}>{searchError}</span>
                        {liveSearch && (
                            <div className="search_backdrop" onClick={() => this.setState({ liveSearch: false })}>
                                <div className="search_info">
                                    <div className="wrapper_scroll">
                                        <div className="title_search">Товары</div>
                                        <div className="product_search">
                                            {searchResult.products.length > 0 ? (
                                                searchResult.products.map((el, idx) => (
                                                    <div key={idx} onClick={() => this.goToProd(el.id)}>
                                                        <div className="img">
                                                            <img src={el.images[0] || nonAvatart} alt="product" />
                                                        </div>
                                                        <div className="prod_info">
                                                            <div className="name">{el.name}</div>
                                                            <div className="wrapper_info">
                                                                <div className="art">Арт. {el.article_number}</div>
                                                                {!!el.discount_price ? (
                                                                    <div className="price stock">
                                                                        {el.discount_price} грн{" "}
                                                                        <p>{el.retail_price} грн</p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="price">
                                                                        {el.has_id_1c === false ?
                                                                            <div>{el.price} грн</div>
                                                                            :
                                                                            <div>{el.retail_price} грн</div>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <span className={`no_search_items`}>
                                                    По Вашему запросу товаров не найдено
                                                </span>
                                            )}
                                        </div>

                                        <div className="info">
                                            <p>Категории</p>
                                            {searchResult.categories.length > 0 ? (
                                                searchResult.categories.map((el, idx) => (
                                                    <span onClick={() => this.goToCat(el.id)} key={idx}>
                                                        {el.name}
                                                    </span>
                                                ))
                                            ) : (
                                                <span className={`no_search_items`}>Категорий нет</span>
                                            )}
                                        </div>
                                    </div>
                                    <button onClick={this.applySearch} disabled={searchLoading}>
                                        {searchLoading
                                            ? <CircularProgress color="inherit" size={24} />
                                            : `Все результаты поиска (${searchResult.total_count})`
                                        }
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="panel_info">
                        {/* <button className={`send_client`} onClick={() => this.toggleClientModal("open")}>
                            <img src={SendClient} alt="send_client" />
                        </button> */}
                        {/* <NavLink to="/main/shop">
                            <img className="grey" src={shop_grey} alt="shop_grey" />
                            <img className="red" src={shop_red} alt="shop_red" />
                        </NavLink> */}
                        <NavLink to="/main/camera">
                            <BarcodeIcon />
                        </NavLink>
                        <NavLink to="/main/client">
                            <img className="grey" src={user_grey} alt="user_grey" />
                            <img className="red" src={user_red} alt="user_red" />
                        </NavLink>
                        <NavLink to="/main/product">
                            <img className="grey" src={product_grey} alt="product_grey" />
                            <img className="red" src={product_red} alt="product_red" />
                        </NavLink>
                    </div>
                    <div
                        className="header-cart"
                        onClick={this.props.loc !== "/main/client/info" ? this.toggleDialog : null}
                    >
                        <div className="header-cart-img">
                            <img src={cart_img} alt="cart_img" />
                            {<div>{cart && cart.reduce((x, y) => x + Number(y.amount), 0)}</div>}
                        </div>
                        <div className="header-cart-other">
                            <p className="total">Итого:</p>
                            <p className="price">{totalWithDiscount} грн.</p>
                        </div>
                    </div>
                </div>
                <div className="menu" onClick={this.openMenu}>
                    <div className="avatar">
                        <img src={accountInfo.photo || avatar} alt="avatar" />
                    </div>
                    <div className="menu_info">
                        <span>{`${accountInfo.first_name} ${accountInfo.last_name}`}</span>
                        <p>{accountInfo.role}</p>
                    </div>
                </div>
                {menuIsOpen ? (
                    <ClickAwayListener onClickAway={this.handleOpenMenu}>
                        {/* <div onClick={this.handleOpenMenu} className="logout-backdrop"> */}
                        <div className="controls_wrapper">
                            <div className="controls_wrapper-sales">
                                <p className="sales">Мои продажи</p>
                                <p className="prices">{moment(new Date()).format("DD.MM.YYYY")}</p>
                            </div>
                            <div className="controls_wrapper-days">
                                {loadingDrop ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%"
                                        }}
                                    >
                                        <CustomSpinner styles={{ marginTop: "2px", marginBottom: "6px" }} />
                                        <p>Сбор информации</p>
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            <span className="digits">{data && data.saleAmountDay}</span>
                                            <span>грн.</span>
                                            <p>сегодня</p>
                                        </div>
                                        <div>
                                            <span className="digits">{data && data.saleAmountMonth}</span>
                                            <span>грн.</span>
                                            <p>за месяц</p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="controls_wrapper-sales">
                                <p className="sales">Продажи магазина</p>
                            </div>
                            <div className="controls_wrapper-days">
                                {loadingDrop ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%"
                                        }}
                                    >
                                        <CustomSpinner styles={{ marginTop: "2px", marginBottom: "6px" }} />
                                        <p>Сбор информации</p>
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            <span className="digits">{data && data.saleAmountWhsDay}</span>
                                            <span>грн.</span>
                                            <p>сегодня</p>
                                        </div>
                                        <div>
                                            <span className="digits">{data && data.saleAmountWhsMonth}</span>
                                            <span>грн.</span>
                                            <p>за месяц</p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <hr />
                            <div className="controls_wrapper-pricePicker">
                                <p>Ціна</p>
                                <div className="controls_wrapper-pricePicker-wrapper">
                                    <button
                                        className={visiblePrice === 'wholesale_price' ? 'active' : ''}
                                        onClick={() => setVisiblePrice('wholesale_price')}
                                    >
                                        Опт
                                    </button>
                                    <button
                                        className={visiblePrice === 'retail_price' ? 'active' : ''}
                                        onClick={() => setVisiblePrice('retail_price')}
                                    >
                                        Роздріб
                                    </button>
                                    <div className="activeIndicator" />
                                </div>
                            </div>
                            <Link to="#">
                                <img src={statsImg} alt="stats" />
                                <p>Показатели</p>
                            </Link>
                            <Link to="#">
                                <img src={motivationImg} alt="stats" />
                                <p>Мотивация</p>
                            </Link>
                            <div onClick={this.handleOut} className="quit">
                                <img src={quitImg} alt="quit" /> <p>Выйти</p>
                            </div>
                        </div>
                        {/* </div> */}
                    </ClickAwayListener>
                ) : null}
                <DialogComponent open={clientModal === "open"} onClose={() => this.toggleClientModal("close")}>
                    <div className="dialog_client">
                        <div>
                            Отправить в зону <br />
                            комплектации?
                        </div>
                        <div className="button_container">
                            <DefaultButton variant="contained" onClick={this.sendClient} disabled={buttonLoad}>
                                {buttonLoad && <CircularProgress color="secondary" />}
                                Да
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleClientModal("close")}
                                classes={`disline`}
                            >
                                Нет
                            </DefaultButton>
                            {sendClientError !== "false" && <span className={`error_block`}>{sendClientError}</span>}
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={clientModalSuccess} onClose={() => this.setState({ clientModalSuccess: false })}>
                    <div className="dialog_client">
                        <img src={Frame_big} alt="Frame_big" />
                        <div>
                            Клиент отправлен <br />в зону комплектации
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent
                    open={openCart}
                    className="custom_dialog"
                    onClose={this.toggleDialog}
                >
                    <div className="dialog_cart">
                        <CartDialog />
                        <div className="total_all">Итого: {totalAll} грн.</div>
                        <DefaultButton
                            onClick={this.toggleDialog}
                            type="link"
                            to='/main/client'
                            variant="contained"
                        >
                            Оформить заказ
                        </DefaultButton>
                    </div>
                </DialogComponent>
            </header>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2, 5}$/i.test(values.email)) {
        errors.email = "Invalid email";
    }
    if (!values.password) {
        errors.password = "Required";
    } else if (values.password.length < 8) {
        errors.password = "Must be 8 characters or more";
    }
    return errors;
};

Header = reduxForm({
    form: "HeaderForm",
    validate
})(Header);

const mapStateToProps = ({ auth, client, search, category, products }) => {
    return {
        accountInfo: auth.account,
        searchResult: search.search_result,
        searchLoading: search.loading,
        categoryError: category.error,
        productsError: products.error,
        cartUpdate: client.cart_update,
        cart: client.cart,
        data: auth.shopData,
        visiblePrice: auth.visiblePrice,

        clientInfo: client.client_info,
        needDelivery: client.needDelivery,
        selectedDate: client.selectedDate,
        deliveryAddress: client.deliveryAddress
    };
};

const mapDispatchToProps = {
    logout,
    personalInfo,
    getSearchResults,
    sendToPicking,
    getHeaderDropData,
    getHeaderDropShop,

    GetClientInfo,
    sessionEnd,
    cartUpdate,
    setNeedDelivery,
    setAddress,
    setDate,
    setVisiblePrice
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
